<template>
<div>
  <remote-js :js-url='"https://www.yunpian.com/static/official/js/libs/riddler-sdk-0.2.2.js"' :js-load-call-back="loadRongJs"></remote-js>
  <div id="cbox"></div>
</div>
</template>

<script>
import RemoteJs from '../components/remotejs'// 导入组件
export default {
  components: {
    RemoteJs
  },
  mounted() {
  },
  methods: {
    loadRongJs() {
         const THIS = this
       // 当使用远程js里的内容时请添加"//eslint-disable-line"防止eslint检测报错
          new YpRiddler({
              expired: 10,
              mode: 'flat',
              winWidth: 500,
              lang: 'zh-cn', // 界面语言, 目前支持: 中文简体 zh-cn, 英语 en
              // langPack: LANG_OTHER, // 你可以通过该参数自定义语言包, 其优先级高于lang
              container: document.getElementById('cbox'),
              appId: 'edc8118f43b04f2486d96832650c7358',
              version: 'v1',
              onError: function (param) {
                 THIS.$emit('onError',param)
                  if (!param.code) {
        
                  THIS.$message.error('错误请求')

                  } else if (parseInt(param.code / 100) == 5) {
                      // 服务不可用时，开发者可采取替代方案
        
                  THIS.$message.warning('验证服务暂不可用')

                  } else if (param.code == 429) {

                  THIS.$message.warning('请求过于频繁，请稍后再试')

                  } else if (param.code == 403) {

                  THIS.$message.warning('请求受限，请稍后再试')

                  } else if (param.code == 400) {
                      
                  THIS.$message.warning('非法请求，请检查参数')

                  }
                  // 异常回调
                THIS.$message.error('验证服务异常')
              },
              onSuccess: function (validInfo, close, useDefaultSuccess) {
        
                  //   alert('验证通过! token=' + validInfo.token + ', authenticate=' + validInfo.authenticate)
                  // 验证成功默认样式
                  useDefaultSuccess(true)
                  close()
                  THIS.$emit('onSuccess',validInfo)
              },
              onFail: function (code, msg, retry) {
                  // 失败回调
                //   THIS.$message.error('出错啦：' + msg + ' code: ' + code)
                  retry()
                  THIS.$emit('onFail',code,msg)
              },
              beforeStart: function (next) {
                  console.log(123)
                  console.log('验证马上开始')
                  next()
              },
              onExit: function () {
                  // 退出验证 （仅限dialog模式有效）
                  THIS.$message.warning('退出验证')
              }
          })
    }
  }
}
</script>

<style>

</style>