<!--  -->
<template>
  <div class="register">
    <div class="register-top">
      <div class="top-l">
        <img src="../../assets/images/register/logo.png" alt="" />
        <span>爱玩就上爱租号</span>
      </div>
      <div class="top-r">
        <ul>
          <li @click="$router.push({ path: '/home' })">首页</li>
          <li @click="$router.push({ path: 'register' })">用户注册</li>
          <li @click="$router.push({path:'/center'})">帮助中心</li>
        </ul>
      </div>
    </div>
    <div class="register-center">
      <div class="cards">
        <el-card class="box-card" v-if="loginShow == 0">
          <h2>登录</h2>
          <p class="login-p" @click="checkoutLogin(1)">手机号快捷登录</p>
          <el-form ref="form">
            <el-input
              placeholder="请输入账号"
              prefix-icon="el-icon-user"
              v-model="phoneCode"
            >
              <i slot="suffix" class="el-icon-error" @click="showPwd"></i>
            </el-input>
            <el-input
              placeholder="请输入密码"
              prefix-icon="el-icon-lock"
              v-model="password"
              show-password
              @keyup.enter.native="login"
            >
            </el-input>
          </el-form>
          <div class="login">
            <el-checkbox v-model="checked" size="mini" v-if="false">自动登录</el-checkbox>
            <div
              class="login-top"
              :class="{ active: true }"
              @click="$router.push({ path: 'register' })"
            >
              <span class="login-res">免费注册</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="card-btn">
            <el-button type="primary" round size="medium" @click="login">
              登录
            </el-button>
          </div>
          <p v-show="false">其它登录方式</p>
          <div class="card-bottom">
            <div class="card-left" v-show="false">
              <img src="../../assets/images/register/qq2.png" alt="" />
              <img src="../../assets/images/register/wx2.png" alt="" />
            </div>
            <div class="card-right" @click="showBj = true">
              <span class="card-spen">忘记密码？</span>
            </div>
          </div>
          <div class="login-img" v-show="false">
            <img src="../../assets/images/login/ewm1.png" alt="" class="img1" />
            <img src="../../assets/images/login/ewm2.png" alt="" class="img2" />
          </div>
        </el-card>
        <el-card class="box-card" v-else-if="loginShow == 1">
          <h2>登录</h2>
          <p class="login-p" @click="checkoutLogin(0)">账号密码登录</p>
          <el-form ref="form">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile-phone"
              v-model="phone"
            >
              <i slot="suffix" class="el-icon-error" @click="showPwd"></i>
            </el-input>
            <el-input
              placeholder="请输入验证码"
              prefix-icon="el-icon-chat-dot-square"
              v-model="code"
            >
            </el-input>
            <el-button
              type="primary"
              size="mini"
              plain
              round
              class="buttom-codes"
              @click.stop="getCode"
              :disabled="disabled"
              >{{ loginMessage }}</el-button
            >
          </el-form>
          <div class="login">
            <div></div>
            <el-checkbox v-model="checked" size="mini" v-show="loginShow == 0" v-if="false"
              >自动登录</el-checkbox
            >
            <div
              class="login-top"
              :class="{ active: true }"
              @click="$router.push({ path: 'register' })"
            >
              <span class="login-res">免费注册</span>
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
          <div class="card-btn">
            <el-button type="primary" round size="medium" @click="codeLogin">
              登录
            </el-button>
          </div>
          <p v-show="false">其它登录方式</p>
          <div class="card-bottom">
            <div class="card-left" v-show="false">
              <img src="../../assets/images/register/qq2.png" alt="" />
              <img src="../../assets/images/register/wx2.png" alt="" />
            </div>
            <div class="card-right" @click="showBj = true">
              <span class="card-spen">忘记密码？</span>
            </div>
          </div>
          <div class="login-img" v-show="false">
            <img src="../../assets/images/login/ewm1.png" alt="" class="img1" />
            <img src="../../assets/images/login/ewm2.png" alt="" class="img2" />
          </div>
        </el-card>
        <el-card class="box-card" v-else>
          <h2>扫码登录</h2>
          <div class="card-code">
            <div class="code-top" v-if="time">
              <img src="" alt="" />
            </div>
            <div class="code-top" v-else>
              <img src="" alt="" />
              <div class="code-top-btn">
                <p>二维码已失效</p>
                <el-button size="mini" round type="primary">重新获取</el-button>
              </div>
            </div>
            <div class="code-bottom">打开 <span>爱租号APP</span> 扫码登录</div>
          </div>
          <div class="cardbottom">
            <div class="password-code" @click="checkoutLogin(0)">
              返回密码登录
            </div>
            <div class="line"></div>
            <div class="code-res">免费注册</div>
          </div>
          <p v-show="false">其它登录方式</p>
          <div class="card-bottom">
            <div class="card-left" v-show="false">
              <img src="../../assets/images/register/qq2.png" alt="" />
              <img src="../../assets/images/register/wx2.png" alt="" />
              <!-- <img src="../../assets/images/register/wb2.png" alt=""> -->
            </div>
          </div>
          <div class="login-img" v-show="false">
            <!-- <img src="../../assets/images/login/dl1.png" alt="" class="img1"> -->
            <img src="../../assets/images/login/dl2.png" alt="" class="img2" />
          </div>
        </el-card>
      </div>
      <div class="register-bottom">
        网络文化经营许可证：鄂网文【2020】8888-088号 网站备案号：鄂A1-20200909-1
        增值业务许可证：鄂A1-20200909-1 鄂公网安备：12345678910
      </div>
    </div>
    <div class="bj" v-if="showBj">
      <!-- <div class="bj-center"> -->
      <div class="password card">
        <div class="password-top">
          <p class="id-card">找回密码</p>
          <i class="el-icon-close" @click="showBj = false"></i>
        </div>
        <div class="password-form">
          <el-form ref="form">
            <el-input
              placeholder="请输入手机号"
              prefix-icon="el-icon-mobile-phone"
              v-model="phone"
            >
              <i slot="suffix" class="el-icon-error" @click="showPwd"></i>
            </el-input>
            <el-input
              placeholder="请输入验证码"
              prefix-icon="el-icon-chat-dot-square"
              v-model="Mycode"
            >
            </el-input>
            <el-input
              placeholder="请设置新密码"
              prefix-icon="el-icon-lock"
              type="password"
              v-model="Mypassword"
            >
            </el-input>
            <el-input
              placeholder="请确认新密码"
              prefix-icon="el-icon-lock"
              type="password"
              v-model="newPassword"
            >
            </el-input>
            <el-button
              type="primary"
              size="mini"
              plain
              round
              class="buttom-code"
              @click.stop="getCode"
              :disabled="disabled"
              >{{loginMessage}}</el-button
            >
          </el-form>
          <el-button type="primary" round size="medium" @click="changePassword"> 确认修改 </el-button>
        </div>
      </div>
    </div>
     <div class="bj" v-if="yp">
        <yp @onSuccess="YpSuccess"></yp>
    </div>
  </div>
</template>
 
<script>
import tokenUtils from "@/common/cookieToken";
// import Verify from 'vue2-verify'

import Yp from '../../components/Yp'
export default {
  name: "login",
  data() {
    return {
      yp: false,
      tokens: '',
      barSize:{width:'100%',height:'35px'},
      //  账号登录
      phoneCode: "",
      password: "",
      checked: "",
      // 手机登录
      loginMessage: "获取验证码",
      phone: "",
      code: "",
      disabled: false,
      //登录切换
      loginShow: 0,
      time: true,
      num: 0,
      // 找回密码
       showBj: false,
       newPassword:'',
       Mypassword:'', 
       Mycode: '',
    };
  },
  components: {
    // Verify,
    Yp
  },
  mounted() {
   tokenUtils.getCookie();
  },
  methods: {
    // 公共url方法
    getCommpara() {
      const commpara = this.$route.params;
      return commpara;
    },
    // 账号登录
    login() {
      let params = new URLSearchParams(location.search);
      let channel_id =params.get("channel_id")  || null
      if (this.checked == true) {
        tokenUtils.setCookie(this.phoneCode, this.password, 7);
      } else {
        tokenUtils.clearCookie();
      }
      if (this.phoneCode && this.password) {
        let commpara = this.getCommpara();
      
        this.$post(
          "api/user/login",
          {
            phonenum: this.phoneCode,
            password: this.password,
            reg_type: 1,
            channel_id:channel_id
          },
          commpara
        ).then((res) => {
          if (res.code == 0) {
            console.log(res, "res");
            this.$message({
              message: "登录成功",
              type: "success",
            });
            sessionStorage.setItem('token',res.ret.user_token)
            sessionStorage.setItem('ext_cash',res.ret.ext_cash)
            sessionStorage.setItem('avatar',res.ret.avatar)
            sessionStorage.setItem('nick_name',res.ret.nick_name)
            this.$store.commit("isLogin", res.ret.user_id);

            setTimeout(() => {
              this.$router.push({
                path: "/home",
                params: commpara,
              });
            }, 1000);
          } else {
            this.$message.error("账号密码不对!");
          }
        });
      } else {
        this.$message({
          message: "账号或密码不能为空!",
          type: "warning",
        });
      }
    },
    // 手机号登录
    codeLogin() {
      let params = new URLSearchParams(location.search);
      let channel_id =params.get("channel_id")  || null
      let that = this;
      if (that.phone && that.code) {
        const commpara = that.getCommpara();
        that
          .$post(
            "api/user/login",
            { phonenum: that.phone, vcode: that.code, reg_type: 2 ,channel_id:channel_id},
            commpara
          )
          .then((res) => {
            console.log(res,123);
            if (res.code == 0) {
              that.$message.success("登录成功");
              sessionStorage.setItem('ext_cash',res.ret.ext_cash)
              sessionStorage.setItem('token',res.ret.user_token)
              sessionStorage.setItem('userId',res.ret.user_id)
              sessionStorage.setItem('nick_name',res.ret.nick_name)
              sessionStorage.setItem('avatar',res.ret.avatar)
              this.$store.commit("isLogin", res.ret.user_id);
              setTimeout(() => {
                that.$router.push({
                  path: "/home",
                  params: commpara,
                });
              }, 1000);
            } else {
              that.$message.error(res.message);
            }
          });
      } else {
        that.$message.warning("手机号或验证码不能为空!");
      }
    },
    // 获取验证码
    getCode() {
      let that = this;
      let rule = /^1[3|4|5|7|8][0-9]{9}$/;
      if (!rule.test(that.phone)) {
        that.$message.warning("请填写正确手机号码");
        return;
      }
      this.yp = true
    },

    // 请求二维码接口
    codeM() {
      var that = this
         const commpara = that.getCommpara();
      that
        .$post("api/verify_code", { target: that.phone, type: 1,token:this.tokens }, commpara)
        .then((res) => {
          console.log(res, "ser");
          if (res.code == 0) {
            that.$message.success("发送成功");
             // 60秒倒计时
            let time = 60;
            let timer = setInterval(() => {
              if (time <= 0) {
                that.disabled = false;
                that.loginMessage = "获取验证码";
                clearInterval(timer);
              } else {
              // 将按钮设置为不可点击状态
                that.disabled = true;
                that.loginMessage = time + "s后重试";
                time--;
              }
            }, 1000);
          } else {
            that.$message.error(res.message);
          }
        });
    },
    // 清除按钮
    showPwd() {
      this.phoneCode = "";
      this.password = "";
      this.phone = "";
      this.code = "";
      this.newPassword='',
      this.Mypassword='', 
      this.Mycode= '',
      this.Myphone=''
    },
    // 找回密码
    changePassword() {
        var that = this;
        let rule = /^1[3|4|5|7|8][0-9]{9}$/;
        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/;
      if(that.Mycode.length < 4){
        that.$message.warning('请输入正确的验证码');return;
      }
      if(!reg.test(that.Mypassword)){
        that.$message.warning('密码至少包含 数字和英文，长度6-20');return;
      }
       if(that.newPassword != that.Mypassword){
        that.$message.warning('两次密码不一致');return;
      }
      let commpara = that.getCommpara()
      that.$post('api/user/reset_pwd', {phonenum:that.phone,vcode:that.Mycode,password:that.newPassword,reg_type:2}, commpara).then(res => {
        if (res.code == 0) {
          that.$message.success('修改成功');
          that.showBj = false
        }else{
          that.$message.error(res.message);
        }
      })
    },
    //登录切换
    checkoutLogin(id) {
      this.loginShow = id;
      this.time = true;
      // 二维码状态
      let that = this;
      let auth_timetimer = null;
      that.num = 3;
      if (id == 3) {
        clearInterval(auth_timetimer);
        auth_timetimer = setInterval(() => {
          that.num--;
          if (that.num <= 0) {
            that.time = false;
            clearInterval(auth_timetimer);
          }
        }, 1000);
      } else {
        clearInterval(auth_timetimer);
      }
    },

     YpSuccess(info){
        this.tokens = info.token
        this.$post('api/user/authenticate', {authenticate:info.authenticate, token:info.token}).then(res => {
        if (res.code == 0) {
          this.yp = false
          this.codeM()
        }else{
          this.$message.error("请刷新页面");
        }
      })
    },
  },
  computed: {},
};
</script>
 
<style lang='scss' >
.register {
  width: 100%;
  height: 86px;
  .register-top {
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    .top-l {
      display: flex;
      align-items: center;
      img {
        width: 182px;
        height: 45px;
      }
      span {
        font-size: 13px;
        color: #83899d;
        margin-left: 13px;
      }
    }
    .top-r {
      ul {
        display: flex;
        align-items: center;
        li {
          font-size: 14px;
          color: #83899d;
          margin-left: 29px;
          cursor: pointer;
        }
      }
    }
  }
  .register-center {
    width: 100%;
    height: 868px;
    background: url(../../assets/images/register/bj.png) no-repeat center;
    background-size: 100% 100%;
    position: relative;
    .register-bottom {
      width: 100%;
      height: 40px;
      text-align: center;
      line-height: 45px;
      font-size: 12px;
      color: #989aa2;
      position: absolute;
      bottom: 0;
      left: 0;
    }
    .cards {
      height: 100%;
      width: 1200px;
      margin: 0 auto;
      position: relative;
      .box-card {
        position: absolute;
        top: 150px;
        right: 70px;
        width: 340px;
        height: 460px;
        .el-card__body {
          padding: 0px 40px;
          h2 {
            margin: 49px 0 20px;
          }
          .login-p {
            font-size: 14px;
            text-align: right;
            color: #3c7efe;
            margin-bottom: 16px;
            cursor: pointer;
          }
          .el-input {
            margin-bottom: 16px;
            border-radius: 12px;
            .el-icon-error {
              margin: 14px 7px 0 0;
            }
          }
          .buttom-codes {
            position: absolute;
            top: 40.5%;
            right: 47px;
          }
          .card-btn {
            width: 100%;
            height: 73px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }
          p {
            font-size: 14px;
            color: #a1a6b7;
          }
          .login {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .login-top {
              font-size: 14px;
              cursor: pointer;
            }
          }
          .card-bottom {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 20px 0 0;
            .card-left {
              img {
                width: 32px;
                height: 32px;
                cursor: pointer;
              }
            }
            .card-right {
              cursor: pointer;
              color: #010101;
              .card-spen {
                font-size: 14px;
                // color: #A1A6B7;
              }
            }
          }
          .login-img {
            .img1 {
              position: absolute;
              top: 0;
              right: 0;
              z-index: 3;
            }
            .img2 {
              position: absolute;
              top: 0;
              right: 0;
            }
            cursor: pointer;
            &.login-img:hover .img2 {
              z-index: 6;
            }
          }
          .card-code {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .code-top {
              width: 180px;
              height: 180px;
              border: 1px solid #eee;
              margin-top: 11px;
              position: relative;
              img {
                width: 100%;
                height: 100%;
              }
              .code-top-btn {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: transparent;
                p {
                  font-size: 13px;
                  color: #000;
                  margin-bottom: 3px;
                }
              }
            }
            .code-bottom {
              font-size: 13px;
              margin: 15px 0 23px;
              span {
                color: #3c7efe;
              }
            }
          }
          .cardbottom {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            margin: 0 0 20px;
            .password-code {
              color: #a2a6b8;
              cursor: pointer;
            }
            .line {
              width: 1px;
              height: 12px;
              margin: 0 7px;
              background-color: #000;
            }
            .code-res {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .bj {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
    .password {
      width: 590px;
      height: 540px;
      padding: 0 38px;
      position: relative;
      .password-top {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #000000;
        margin-top: 28px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        i{
            font-size: 24px;
        }
        p {
          position: relative;
          padding-left: 13px;
          &::before {
            content: "";
            position: absolute;
            top: 4px;
            left: 0px;
            width: 4px;
            height: 17px;
            background: #3c7efe;
            border-radius: 2px;
          }
        }
      }
      .password-form {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 340px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .el-form {
          position: relative;
          .el-input {
            margin-bottom: 16px;
            border-radius: 12px;
            .el-icon-error {
              margin: 14px 7px 0 0;
            }
          }
          .buttom-code {
            position: absolute;
            top: 28%;
            right: 6px;
          }
        }
      }
    }
  }
}
.el-checkbox__label {
  display: inline-block;
  padding-left: 10px;
  line-height: 19px;
  font-size: 12px;
}
.el-button--medium.is-round {
  padding: 10px 60px;
}
.active {
  color: #3c7efe !important;
}
.card {
  width: 100%;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #ebeef5;
  background-color: #fff;
  transition: 0.3s;
  border-radius: 12px;
  overflow: hidden;
  margin-bottom: 10px;
}
</style>